<template>
  <div id="redirect-text">
    Redirecting...
  </div>
</template>

<script>
import axios from 'axios'

const fetchUrl = process.env.VUE_APP_LAZADA_REDIRECT_URL

export default {
  mounted: {
    async fetchSeller() {
      try {
        const authCode = this.$route.query.code
        const { data } = await axios.post(`${fetchUrl}?code=${authCode}`)
        //console.log(data, '<<<<<<<<<<< Sellerdata')
        if (!data) throw new Error('Error fetching seller Data')
        this.$router.push({
          path: '/channels',
          params: {
            name: data.data.name,
            email: data.data.email,
            seller_id: data.data.seller_id,
            status: data.data.status,
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style>
#redirect-text {
  size: 20px;
}
</style>